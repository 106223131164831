import { Divider, Modal } from 'antd';
import './modal.less';
const StickModal = ({ open, onClose, footer, className = '', children, ...properties }) => {
    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={footer ?? null}
            {...properties}
            className={`stick-modal ${className}`}>
            {children}
        </Modal>
    );
};

StickModal.Header = ({ children }) => <div className='stick-modal-header'>{children}</div>;

StickModal.Divider = () => (
    <div className='stick-modal-divider'>
        <Divider />
    </div>
);

StickModal.Body = ({ children }) => <div className='stick-modal-body'>{children}</div>;

StickModal.Footer = ({ children }) => <div className='stick-modal-footer'>{children}</div>;

export default StickModal;
