import { useMemo } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import mime from 'mime';

const useAxios = (baseURL, defaultConfig = {}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const { locale } = useSelector((state) => state.user);

    const http = useMemo(() => {
        const instance = axios.create({
            baseURL: baseURL || '/',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Expose-Headers': '*',
                ...defaultConfig.headers
            },
            ...defaultConfig
        });

        instance.interceptors.request.use(
            async (config) => {
                const token = await getAccessTokenSilently();

                if (locale) {
                    config.headers['Accept-Language'] = locale;
                }

                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                switch (error.statusCode) {
                    case 401: {
                        await logout({ returnTo: window.location.origin });

                        break;
                    }
                    //case 403: {
                    //    navigate('/unauthorized');

                    //    break;
                    //}
                    //case 404: {
                    //    navigate('/notfound');

                    //    break;
                    //}
                    //case 429: {
                    //    navigate('/toomanyrequests');

                    //    break;
                    //}
                    default: {
                        if (axios.isCancel(error)) {
                            return;
                        }

                        //TODO: Display error message
                        //dispatch(displayError(error));

                        break;
                    }
                }

                throw error;
            }
        );

        return instance;
    }, [baseURL, defaultConfig, getAccessTokenSilently, locale]);

    return useMemo(
        () => ({
            http,

            get(resource, parameters) {
                return http.get(`api/${resource}`, parameters);
            },

            post(resource, parameters, config) {
                return http.post(`api/${resource}`, parameters, config);
            },

            update(resource, slug, parameters) {
                return http.put(`api/${resource}/${slug}`, parameters);
            },

            put(resource, parameters) {
                return http.put(`api/${resource}`, parameters);
            },

            patch(resource, parameters, config) {
                return http.patch(`api/${resource}`, parameters, config);
            },

            delete(resource, parameters) {
                return http.delete(`api/${resource}`, parameters);
            },

            async download(resource, parameters = {}, config = {}, method = 'get') {
                const processFileDownload = (response) => {
                    const contentDisposition = response.headers['content-disposition'];
                    const contentType = response.headers['content-type'];
                    let filename = 'download';

                    if (contentDisposition) {
                        const matches = contentDisposition.match(/filename\*?=(?:UTF-8'')?([^;\n]*)/);
                        if (matches && matches[1]) {
                            filename = decodeURIComponent(matches[1].replace(/"/g, '').trim());
                        }
                    } else {
                        const fallbackResourceName = resource.split('/').pop() || 'file';
                        const timestamp = new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
                        filename = `${fallbackResourceName}_${timestamp}`;
                    }

                    if (!/\.[a-zA-Z0-9]+$/.test(filename)) {
                        const extension = mime.getExtension(contentType);
                        if (extension) {
                            filename += `.${extension}`;
                        }
                    }

                    const blob = new Blob([response.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);

                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                };

                const requestConfig = { ...config, responseType: 'blob' };

                if (method.toLowerCase() === 'post') {
                    try {
                        const response = await http.post(`api/${resource}`, parameters, requestConfig);
                        return processFileDownload(response);
                    } catch (error) {
                        console.error('File download failed:', error);
                        throw error;
                    }
                }

                try {
                    const response = await http.get(`api/${resource}`, { ...requestConfig, params: parameters });
                    return processFileDownload(response);
                } catch (error_1) {
                    console.error('File download failed:', error_1);
                    throw error_1;
                }
            }
        }),
        [http]
    );
};

export default useAxios;
