/* These are the states derived from the base selectors */

import { AssessmentFrameworkEnum } from '@/components/enum/FrameworkEnum';
import { createSelector } from '@reduxjs/toolkit';
import * as baseSelectors from './baseSelectors';
import {
    getRequirementTypeDisplay,
    getStatusIcon,
    handleISOData,
    handleNISTData,
    handleNISTv2Data,
    handlePCIData,
    handleSOCData
} from '../helpers';
import { AssessmentProgressStatus, ControlStatus, findControlStatusById } from '@/features/maturity-assessment/enums';
import { createSelectorsForSlice } from './selectorUtils';

/* base selectors based on sliceName for the derived selectors */
const internalBaseSelectors = createSelectorsForSlice(baseSelectors, 'internal');
const assessmentBaseSelectors = createSelectorsForSlice(baseSelectors, 'assessment');

const currentBaseSelector = Object.freeze({
    internal: internalBaseSelectors,
    assessment: assessmentBaseSelectors
});

/* base selectors list */
const selectDefaultControlMaturityStatus = (sliceName) =>
    createSelector([currentBaseSelector[sliceName].selectAllControlMaturityStatuses], (controlMaturityStatuses) => {
        let defaultControlMaturityStatus;
        if (controlMaturityStatuses && controlMaturityStatuses.length > 0) {
            const filteredControlMaturityStatusArray = controlMaturityStatuses.filter(
                (item) => item.controlMaturityRatingId === null || item.controlMaturityRatingId === undefined
            );
            defaultControlMaturityStatus =
                filteredControlMaturityStatusArray.length > 0
                    ? filteredControlMaturityStatusArray[0]
                    : controlMaturityStatuses.sort((a, b) => a.position - b.position)[0];
        } else {
            defaultControlMaturityStatus = null;
        }
        return defaultControlMaturityStatus;
    });

const selectControlSetsByCategoryId = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAllControlSets, (_, controlCategoryId) => controlCategoryId],
        (controlSets, controlCategoryId) =>
            controlSets.filter((controlSet) => controlSet.controlCategoryId === controlCategoryId)
    );

const selectControlsByCategoryIdAndSetId = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControls,
            (_, controlCategoryId) => controlCategoryId,
            (_, __, controlSetId) => controlSetId
        ],
        (controls, controlCategoryId, controlSetId) =>
            controls.filter(
                (control) => control.controlCategoryId === controlCategoryId && control.controlSetId === controlSetId
            )
    );

const selectResponseControlStatusByControlId = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectResponseByControlId],
        (response) => response.completionStatusId
    );

const selectCategoryDataForListView = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControlCategories,
            currentBaseSelector[sliceName].selectAllControlSets,
            currentBaseSelector[sliceName].selectAllControls
        ],
        (controlCategories, controlSets, controls) => {
            const categoryList = [];
            for (const category of controlCategories) {
                const sortedControlSets = controlSets.filter(
                    (controlSet) => controlSet.controlCategoryId === category.id
                );
                const controlSetsWithControls = [];
                for (const controlSet of sortedControlSets) {
                    const sortedControls = controls.filter(
                        (control) =>
                            control.controlCategoryId === controlSet.controlCategoryId &&
                            control.controlSetId === controlSet.id
                    );
                    controlSetsWithControls.push({
                        ...controlSet,
                        controls: sortedControls
                    });
                }
                categoryList.push({
                    ...category,
                    controlSets: controlSetsWithControls
                });
            }
            return categoryList;
        }
    );

const selectControlMaturityStatusSummaryReport = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAssessmentState,
            currentBaseSelector[sliceName].selectAllControlCategories,
            currentBaseSelector[sliceName].selectAllControlMaturityStatuses,
            currentBaseSelector[sliceName].selectAllControls,
            currentBaseSelector[sliceName].selectAllControlResponses
        ],
        (assessmentState, controlCategories, controlMaturityStatuses, controls, controlResponses) => {
            switch (assessmentState?.frameworkName) {
                case AssessmentFrameworkEnum.NIST.value: {
                    return handleNISTData(controlCategories, controls, controlResponses, controlMaturityStatuses);
                }
                case AssessmentFrameworkEnum.NISTv2.value: {
                    return handleNISTv2Data(controlCategories, controls, controlResponses, controlMaturityStatuses);
                }
                case AssessmentFrameworkEnum.PCI.value: {
                    return handlePCIData(controlCategories, controls, controlResponses, controlMaturityStatuses);
                }
                case AssessmentFrameworkEnum.SOC.value: {
                    return handleSOCData(controlCategories, controls, controlResponses, controlMaturityStatuses);
                }
                default: {
                    return handleISOData(controls, controlResponses, controlMaturityStatuses);
                }
            }
        }
    );

const selectRequirementTypeReport = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControlMaturityStatuses,
            currentBaseSelector[sliceName].selectAllControlCategories,
            currentBaseSelector[sliceName].selectAllControlSets,
            currentBaseSelector[sliceName].selectAllControls,
            currentBaseSelector[sliceName].selectAllControlResponses,
            (_, requirementTypeId) => requirementTypeId
        ],
        (controlMaturityStatuses, controlCategories, controlSets, controls, controlResponses, requirementTypeId) => {
            const controlsByRequirementType = controls.filter(
                (control) => control.requirementTypeId === requirementTypeId
            );

            const categoryIds = new Set(controlsByRequirementType.map((control) => control.controlCategoryId));

            const controlCategoriesByRequirementType = controlCategories.filter((category) =>
                categoryIds.has(category.id)
            );

            const controlSetIds = new Set(controlsByRequirementType.map((control) => control.controlSetId));

            const controlIds = new Set(controlsByRequirementType.map((control) => control.id));

            const categoryList = [];
            for (const category of controlCategoriesByRequirementType) {
                const sortedControlSets = controlSets.filter(
                    (controlSet) => controlSet.controlCategoryId === category.id && controlSetIds.has(controlSet.id)
                );
                const controlSetsWithControls = [];
                for (const controlSet of sortedControlSets) {
                    const sortedControls = controls
                        .filter(
                            (control) =>
                                control.controlCategoryId === controlSet.controlCategoryId &&
                                control.controlSetId === controlSet.id &&
                                controlIds.has(control.id)
                        )
                        .map((control) => {
                            const controlResponse = controlResponses.find(
                                (response) => response.controlId === control.id
                            );
                            return {
                                ...control,
                                controlResponse: {
                                    ...controlResponse,
                                    controlMaturityStatusName:
                                        controlMaturityStatuses.find(
                                            (controlMaturityStatus) =>
                                                controlMaturityStatus.id === controlResponse?.controlMaturityStatusId
                                        )?.name ?? ''
                                }
                            };
                        });
                    controlSetsWithControls.push({
                        ...controlSet,
                        controls: sortedControls
                    });
                }
                categoryList.push({
                    ...category,
                    controlSets: controlSetsWithControls
                });
            }
            return categoryList;
        }
    );

const mapControlsToIds = (controls) => controls.map((control) => control.id);

const selectCanMove = (controls, controlId, direction) => {
    const controlIds = mapControlsToIds(controls);
    const currentIndex = controlIds.indexOf(controlId);
    return direction === 'forward' ? currentIndex < controlIds.length - 1 : currentIndex > 0;
};

const selectCanMoveForward = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAllControls, (_, controlId) => controlId],
        (controls, controlId) => selectCanMove(controls, controlId, 'forward')
    );

const selectCanMoveBackward = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAllControls, (_, controlId) => controlId],
        (controls, controlId) => selectCanMove(controls, controlId, 'backward')
    );

const selectAssessmentId = (sliceName) => {
    return createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.id
    );
};

const selectFrameworkId = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.frameworkId
    );
const selectKeyFindings = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.keyFindings
    );

const selectAssessmentProgressStatusId = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.assessmentProgressStatusId
    );
const selectIsReadOnly = (sliceName) =>
    createSelector(
        [selectAssessmentProgressStatusId(sliceName)],
        (assessmentProgressStatusId) => assessmentProgressStatusId === AssessmentProgressStatus.Completed.id
    );
const selectIsSavingChanges = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.isSavingChanges
    );
const selectHasUnsavedChanges = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAssessmentState],
        (currentAssessment) => currentAssessment?.hasUnsavedChanges
    );

const selectControlId = (sliceName) => {
    return createSelector(
        [currentBaseSelector[sliceName].selectActiveControlState],
        (activeControl) => activeControl?.controlId
    );
};

const selectAllControlSetStatuses = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControlSets,
            currentBaseSelector[sliceName].selectAllControls,
            currentBaseSelector[sliceName].selectAllControlResponses
        ],
        (controlSets, controls, controlResponses) => {
            const controlSetStatuses = {};

            for (const controlSet of controlSets) {
                const controlSetControlIds = new Set(
                    controls.filter((control) => control.controlSetId === controlSet.id).map((control) => control.id)
                );

                const controlResponsesForControlSet = controlResponses.filter((response) =>
                    controlSetControlIds.has(response.controlId)
                );

                const completedCount = controlResponsesForControlSet.filter(
                    (response) => findControlStatusById(response.completionStatusId) === ControlStatus.Completed
                ).length;

                const totalCount = controlSetControlIds.size;

                const isStarted = controlResponsesForControlSet.some((response) => {
                    const status = findControlStatusById(response.completionStatusId);
                    return status === ControlStatus.Started || status === ControlStatus.Completed;
                });

                const isCompleted = completedCount === totalCount;

                const requirementTypeIds = new Set(
                    controls
                        .filter((control) => control.controlSetId === controlSet.id)
                        .map((control) => control.requirementTypeId)
                );

                const requirementTypeDisplay = getRequirementTypeDisplay(requirementTypeIds);

                controlSetStatuses[controlSet.id] = {
                    isStarted,
                    isCompleted,
                    completedCount,
                    totalCount,
                    requirementTypeDisplay
                };
            }

            return controlSetStatuses;
        }
    );

const selectAllControlCategoryStatuses = (sliceName) => {
    return createSelector(
        [
            currentBaseSelector[sliceName].selectAllControlCategories,
            currentBaseSelector[sliceName].selectAllControls,
            currentBaseSelector[sliceName].selectAllControlResponses
        ],
        (controlCategories, controls, controlResponses) => {
            const controlCategoryStatuses = {};

            for (const controlCategory of controlCategories) {
                const controlCategoryControlIds = new Set(
                    controls
                        .filter((control) => control.controlCategoryId === controlCategory.id)
                        .map((control) => control.id)
                );

                const controlResponsesForControlCategory = controlResponses.filter((response) =>
                    controlCategoryControlIds.has(response.controlId)
                );

                const completedCount = controlResponsesForControlCategory.filter(
                    (response) => findControlStatusById(response.completionStatusId) === ControlStatus.Completed
                ).length;

                const totalCount = controlCategoryControlIds.size;

                const isStarted = controlResponsesForControlCategory.some((response) => {
                    const status = findControlStatusById(response.completionStatusId);
                    return status === ControlStatus.Started || status === ControlStatus.Completed;
                });

                const isCompleted = completedCount === totalCount;

                const requirementTypeIds = new Set(
                    controls
                        .filter((control) => control.controlCategoryId === controlCategory.id)
                        .map((control) => control.requirementTypeId)
                );

                const requirementTypeDisplay = getRequirementTypeDisplay(requirementTypeIds);

                controlCategoryStatuses[controlCategory.id] = {
                    isStarted,
                    isCompleted,
                    completedCount,
                    totalCount,
                    requirementTypeDisplay
                };
            }

            return controlCategoryStatuses;
        }
    );
};

const selectAllControlStatuses = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAllControls, currentBaseSelector[sliceName].selectAllControlResponses],
        (controls, controlResponses) => {
            const controlStatuses = {};

            for (const control of controls) {
                const controlResponsesForControl = controlResponses.filter(
                    (response) => response.controlId === control.id
                );

                const isStarted = controlResponsesForControl.some((response) => {
                    const status = findControlStatusById(response.completionStatusId);
                    return status === ControlStatus.Started || status === ControlStatus.Completed;
                });

                const isCompleted = controlResponsesForControl.every(
                    (response) => findControlStatusById(response.completionStatusId) === ControlStatus.Completed
                );

                const requirementTypeDisplay = getRequirementTypeDisplay(new Set([control.requirementTypeId]));

                controlStatuses[control.id] = {
                    isStarted: isStarted,
                    isCompleted,
                    requirementTypeDisplay
                };
            }

            return controlStatuses;
        }
    );

const selectControlCategoryData = (sliceName) =>
    createSelector(
        [currentBaseSelector[sliceName].selectAllControlCategories, selectAllControlCategoryStatuses(sliceName)],
        (controlCategories, controlCategoryStatuses) => {
            return controlCategories.map((category) => {
                const statusData = controlCategoryStatuses[category.id] || {};
                return {
                    ...category,
                    ...statusData,
                    icon: getStatusIcon(statusData.isCompleted, statusData.isStarted)
                };
            });
        }
    );

const selectControlSetData = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControlSets,
            selectAllControlSetStatuses(sliceName),
            (_, controlCategoryId) => controlCategoryId
        ],
        (controlSets, controlSetStatuses, controlCategoryId) => {
            return controlSets
                .filter((controlSet) => controlSet.controlCategoryId === controlCategoryId)
                .map((category) => {
                    const statusData = controlSetStatuses[category.id] || {};
                    return {
                        ...category,
                        ...statusData,
                        icon: getStatusIcon(statusData.isCompleted, statusData.isStarted)
                    };
                });
        }
    );

const selectControlData = (sliceName) =>
    createSelector(
        [
            currentBaseSelector[sliceName].selectAllControls,
            selectAllControlStatuses(sliceName),
            (_, controlCategoryId) => controlCategoryId,
            (_, __, controlSetId) => controlSetId
        ],
        (controls, controlStatuses, controlCategoryId, controlSetId) => {
            return controls
                .filter(
                    (control) =>
                        control.controlCategoryId === controlCategoryId && control.controlSetId === controlSetId
                )
                .map((control) => {
                    const statusData = controlStatuses[control.id] || {};
                    return {
                        ...control,
                        ...statusData,
                        icon: getStatusIcon(statusData.isCompleted, statusData.isStarted)
                    };
                });
        }
    );

const selectCompletedControlsCount = (sliceName) =>
    createSelector([currentBaseSelector[sliceName].selectAllControlResponses], (controlResponses) => {
        const completedControls = controlResponses.filter(
            (response) => response.completionStatusId === ControlStatus.Completed.id
        );
        const notAssessedControls = controlResponses.filter(
            (response) => response.completionStatusId === ControlStatus.NotStarted.id
        );
        return {
            isAssessmentComplete: completedControls.length === controlResponses.length,
            completedCount: completedControls.length,
            totalCount: controlResponses.length,
            notAssessedCount: notAssessedControls.length
        };
    });

export {
    selectCategoryDataForListView,
    selectControlsByCategoryIdAndSetId,
    selectControlSetsByCategoryId,
    selectDefaultControlMaturityStatus,
    selectResponseControlStatusByControlId,
    selectControlMaturityStatusSummaryReport,
    selectRequirementTypeReport,
    selectCanMoveForward,
    selectCanMoveBackward,
    selectAssessmentId,
    selectFrameworkId,
    selectControlId,
    selectKeyFindings,
    selectAssessmentProgressStatusId,
    selectIsReadOnly,
    selectIsSavingChanges,
    selectHasUnsavedChanges,
    selectAllControlSetStatuses,
    selectAllControlCategoryStatuses,
    selectControlCategoryData,
    selectControlSetData,
    selectControlData,
    selectCompletedControlsCount
};
