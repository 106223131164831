import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-in';

export const setBrowserLocal = () => {
    dayjs.extend(localizedFormat);
    const browserLocale = navigator.language;
    dayjs.locale(browserLocale);
};

export function formatDate(date, withTime = false) {
    if (!date) {
        return null;
    }

    const parsedDate = dayjs(date);
    if (!parsedDate.isValid()) {
        return null;
    }
    const formatString = withTime ? 'L, LT' : 'L';
    const localFormattedDate = parsedDate.format(formatString);
    return localFormattedDate;
}

export function getCurrentLocaleDate() {
    const currentDate = dayjs();
    return currentDate.format('L');
}

export const formatDateTime = (value) => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return dayjs(value)
        .set('hours', hours)
        .set('minutes', minutes)
        .set('seconds', seconds)
        .format('YYYY-MM-DDTHH:mm:ssZ');
};
